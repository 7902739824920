
/* DEPRECATED ############################################################### */
import { defineComponent } from "vue";
import store from "@/store/index";
import { User } from "@/store/workspace/types";
import { gapi } from "gapi-script";
import { signInFlow } from "@/main";
import { SignInState } from "@/store/workspace/types";
import { gapiConfig } from "@/config/gapiConfig";
import router from "@/router/index";

export default defineComponent({
  name: "Collab",
  data() {
    return {
      joining: false,
      joined: false,
      failed: false,
      failedAgain: false,
    };
  },
  created: function () {
    if (store.getters["workspace/loggedIn"]) {
      this.connect();
    }
  },
  methods: {
    connect: function () {
      this.joining = true;
      const collabKey = this.$route.params.collabKey;
      fetch("https://api.tryvelocity.xyz/collab?id=" + collabKey, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + store.getters["user/getAuth"].id_token,
        },
      }).then((response) => {
        if (response.status > 300) this.failed = true;
        else
          response.json().then((result) => {
            console.log(result);
            this.joined = true;
            setTimeout(() => {
              const lastOpen = store.getters["user/getLastOpen"];
              if (lastOpen.workspace.length > 0) {
                this.$router.push({
                  name: "Page",
                  params: {
                    workspaceId: lastOpen.workspace,
                    pageId: lastOpen.page,
                  },
                });
              } else {
                const workspaces = store.getters["user/getWorkspaces"];
                if (workspaces.length > 0) {
                  this.$router.push({
                    name: "Workspace",
                    params: { workspaceId: workspaces[0], pageId: "default" },
                  });
                } else {
                  store
                    .dispatch("workspace/create")
                    .then((workspace: string) => {
                      this.$router.push({
                        name: "Workspace",
                        params: { workspaceId: workspace, pageId: "default" },
                      });
                    });
                }
              }
            }, 1000);
          });
      });
    },
    login: function () {
      this.$gapi.getAuthInstance().then((auth) => {
        auth.signIn({ prompt: "select_account" }).then((result: any) => {
          store.commit("user/updateSignInState", signInFlow());
          store.getters["user/signInState"].then((res: SignInState) => {
            if (res == SignInState.Success) {
              this.connect();
            }
          });
        });
      });
    },
  },
});
